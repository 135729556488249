.extra-logo-illustration--content {
  width: 400px;

  @screen sm {
    width: 600px;
  }
}

.extra-content-text-block {
  max-width: 720px;

  p {
    @apply text-base;
  }

  p.lead {
    @apply text-lg-var;
  }

  ul,
  ol {
    list-style-type: disc;
    padding-top: 0.5em;
    padding-bottom: 2em;
    padding-left: 20px;

    li {
      line-height: 2;
    }
  }

  a {
    @apply text-primary underline transition-all duration-200 hover:text-secondary;
  }

  h2,
  h3,
  h4,
  h5 {
    @apply font-heading font-medium text-primary mt-24 mb-12;
  }

  p:not(:last-child) {
    @apply mb-24;
  }

  h2 {
    @apply text-lg-var;
  }

  h3 {
    @apply text-md-var;
  }
}

.extra-content-sidebar {
  @apply pb-40;
  width: 100%;

  @screen sm {
    @apply pb-0;
    width: 260px;
  }
}

.extra-content-content {
  width: 100%;

  @screen sm {
    width: calc(100% - 260px);
  }
}

.extra-content-page-nav {
  a {
    @apply block font-medium text-secondary py-8 transition-all border-dotted border-b border-b-black hover:text-primary;
  }
}
