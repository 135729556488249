.e4-dropdown .flatpickr-calendar,
.e4-dropdown .flatpickr-rContainer,
.e4-dropdown .flatpickr-weekdays,
.e4-dropdown .flatpickr-days {
  width: 100% !important;
}

.e4-flatpickr-calendar-wrap {
  .flatpickr-calendar {
    @apply shadow-none;
  }
}

.e4-dropdown-calendar .flatpickr-calendar {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}
.e4-dropdown-calendar .flatpickr-calendar input {
  min-width: auto;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  input[type='number']::-webkit-inner-spin-button,
.e4-dropdown-calendar
  .flatpickr-calendar
  input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-current-month
  .flatpickr-monthDropdown-months:hover,
.e4-dropdown-calendar .flatpickr-calendar .cur-month:hover {
  background: none;
}
.e4-dropdown-calendar .flatpickr-calendar .numInputWrapper .arrowUp,
.e4-dropdown-calendar .flatpickr-calendar .numInputWrapper .arrowDown {
  display: none;
}
.e4-dropdown-calendar .flatpickr-calendar .numInputWrapper:hover,
.e4-dropdown-calendar .flatpickr-calendar .numInputWrapper:focus {
  background: none;
}
.e4-dropdown-calendar .flatpickr-calendar select {
  -webkit-appearance: none;
}
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day {
  border: 1px solid #eeeeee;
  border-radius: 0;
  margin-top: 2px;
  max-width: 42px;
  height: 42px;
  line-height: 42px;
}
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.selected,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.startRange,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.endRange,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.selected.inRange,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.startRange.inRange,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.endRange.inRange,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.selected:focus,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.startRange:focus,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.endRange:focus,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.selected:hover,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.startRange:hover,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.endRange:hover,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.selected.prevMonthDay,
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.startRange.prevMonthDay,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.endRange.prevMonthDay,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.selected.nextMonthDay,
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.startRange.nextMonthDay,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.endRange.nextMonthDay {
  background: #00508a;
  border-color: #00508a;
}
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -2px 0 0 #b1e6f7, 2px 0 0 #b1e6f7;
  box-shadow: -2px 0 0 #b1e6f7, 2px 0 0 #b1e6f7;
  border: 1px solid #b1e6f7;
}
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.inRange,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.prevMonthDay.inRange,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.nextMonthDay.inRange,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.today.inRange,
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.prevMonthDay.today.inRange,
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.nextMonthDay.today.inRange,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day:hover,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.prevMonthDay:hover,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.nextMonthDay:hover,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day:focus,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.prevMonthDay:focus,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.nextMonthDay:focus {
  background: #b1e6f7;
}
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.today {
  color: #ff7f00;
  font-weight: 500;
}
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.today.disabled,
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.today.flatpickr-disabled {
  color: rgba(255, 127, 0, 0.6);
}
.e4-dropdown-calendar .flatpickr-calendar .startRange {
  position: relative;
  border-radius: 0 !important;
}
.e4-dropdown-calendar .flatpickr-calendar .startRange:before {
  position: absolute;
  content: '';
  top: 50%;
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-left: 6px solid #00508a;
  border-bottom: 7px solid transparent;
  right: -5px;
  z-index: 1;
}
.e4-dropdown-calendar .flatpickr-calendar .endRange {
  position: relative;
  border-radius: 0 !important;
}
.e4-dropdown-calendar .flatpickr-calendar .endRange:before {
  position: absolute;
  content: '';
  top: 50%;
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 6px solid #00508a;
  border-bottom: 7px solid transparent;
  left: -7px;
  z-index: 1;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.selected.startRange
  + .endRange:not(:nth-child(7n + 1)),
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.startRange.startRange
  + .endRange:not(:nth-child(7n + 1)),
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.endRange.startRange
  + .endRange:not(:nth-child(7n + 1)) {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-current-month
  span.cur-month {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.125rem;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-current-month
  input.cur-year {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.125rem;
}
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day:hover {
  background-color: #ff7f00;
  color: #fff;
}
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.disabled,
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.flatpickr-disabled {
  color: #d8d8d8;
  border: 1px solid #eeeeee;
  cursor: pointer;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.disabled
  .e3-calendar-price,
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.flatpickr-disabled
  .e3-calendar-price {
  color: #d8d8d8;
}
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.disabled,
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.flatpickr-disabled:hover {
  background-color: #fff;
  cursor: default;
  border-color: #eeeeee;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.disabled
  .e3-calendar-price,
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.flatpickr-disabled:hover
  .e3-calendar-price {
  color: #d8d8d8 !important;
}
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.today:hover {
  border-color: #fff;
}
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-calendar.multiMonth {
  width: 100% !important;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-calendar.multiMonth
  .flatpickr-rContainer {
  width: 100%;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-calendar.multiMonth
  .flatpickr-days {
  width: 100% !important;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-calendar.multiMonth
  .dayContainer
  + .dayContainer {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-calendar.multiMonth
  .dayContainer {
  -webkit-box-pack: center !important;
  -webkit-justify-content: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  padding-right: 6px;
  padding-left: 6px;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-calendar.multiMonth
  .hidden {
  display: inline-block !important;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-calendar.multiMonth
  .flatpickr-weekdays
  .flatpickr-weekdaycontainer {
  padding-left: 6px;
  padding-right: 6px;
}
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-month input {
  padding: 0 0 0 0.5ch;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-current-month
  input.cur-year {
  height: auto !important;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-calendar
  .flatpickr-day.today.e3-day-green {
  color: #ffb56d !important;
}
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.e3-day-green {
  background: #2eb200;
  color: #fff;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.e3-day-green
  .e3-calendar-price {
  color: #fff;
}
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.e3-day-green.inRange {
  color: #32394c;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.e3-day-green.inRange
  .e3-calendar-price {
  color: #32394c;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.e3-date-red
  .e3-calendar-price {
  color: #d1031b;
}
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.e3-date-red.inRange {
  color: #32394c;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.e3-date-red.inRange
  .e3-calendar-price {
  color: #32394c;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.e3-date-green
  .e3-calendar-price {
  color: #2eb200;
}
.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.e3-date-green.inRange {
  color: #32394c;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.e3-date-green.inRange
  .e3-calendar-price {
  color: #32394c;
}
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.selected.startRange
  .e3-calendar-price,
.e4-dropdown-calendar
  .flatpickr-calendar
  .flatpickr-day.selected.endRange
  .e3-calendar-price {
  color: #fff;
}
.e4-dropdown-calendar .flatpickr-calendar .e3-calendar-price {
  font-size: 0.625rem;
  line-height: 0.625rem;
  position: absolute;
  white-space: nowrap;
  bottom: 1px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #aaa;
}

.e4-modal-container .flatpickr-calendar,
.e3-search-bar .flatpickr-calendar,
.e3-mobile-search-bar .flatpickr-calendar,
.e3-main-search-form .flatpickr-calendar,
.e3-field-drop.e3-field-drop--datepicker .flatpickr-calendar {
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 0;
}
.e4-modal-container .flatpickr-calendar input,
.e3-search-bar .flatpickr-calendar input,
.e3-mobile-search-bar .flatpickr-calendar input,
.e3-main-search-form .flatpickr-calendar input,
.e3-field-drop.e3-field-drop--datepicker .flatpickr-calendar input {
  min-width: auto;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.e4-modal-container
  .flatpickr-calendar
  input[type='number']::-webkit-inner-spin-button,
.e4-modal-container
  .flatpickr-calendar
  input[type='number']::-webkit-outer-spin-button,
.e3-search-bar
  .flatpickr-calendar
  input[type='number']::-webkit-inner-spin-button,
.e3-search-bar
  .flatpickr-calendar
  input[type='number']::-webkit-outer-spin-button,
.e3-mobile-search-bar
  .flatpickr-calendar
  input[type='number']::-webkit-inner-spin-button,
.e3-mobile-search-bar
  .flatpickr-calendar
  input[type='number']::-webkit-outer-spin-button,
.e3-main-search-form
  .flatpickr-calendar
  input[type='number']::-webkit-inner-spin-button,
.e3-main-search-form
  .flatpickr-calendar
  input[type='number']::-webkit-outer-spin-button,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  input[type='number']::-webkit-inner-spin-button,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.e4-modal-container
  .flatpickr-calendar
  .flatpickr-current-month
  .flatpickr-monthDropdown-months:hover,
.e4-modal-container .flatpickr-calendar .cur-month:hover,
.e3-search-bar
  .flatpickr-calendar
  .flatpickr-current-month
  .flatpickr-monthDropdown-months:hover,
.e3-search-bar .flatpickr-calendar .cur-month:hover,
.e3-mobile-search-bar
  .flatpickr-calendar
  .flatpickr-current-month
  .flatpickr-monthDropdown-months:hover,
.e3-mobile-search-bar .flatpickr-calendar .cur-month:hover,
.e3-main-search-form
  .flatpickr-calendar
  .flatpickr-current-month
  .flatpickr-monthDropdown-months:hover,
.e3-main-search-form .flatpickr-calendar .cur-month:hover,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-current-month
  .flatpickr-monthDropdown-months:hover,
.e3-field-drop.e3-field-drop--datepicker .flatpickr-calendar .cur-month:hover {
  background: none;
}
.e4-modal-container .flatpickr-calendar .numInputWrapper .arrowUp,
.e4-modal-container .flatpickr-calendar .numInputWrapper .arrowDown,
.e3-search-bar .flatpickr-calendar .numInputWrapper .arrowUp,
.e3-search-bar .flatpickr-calendar .numInputWrapper .arrowDown,
.e3-mobile-search-bar .flatpickr-calendar .numInputWrapper .arrowUp,
.e3-mobile-search-bar .flatpickr-calendar .numInputWrapper .arrowDown,
.e3-main-search-form .flatpickr-calendar .numInputWrapper .arrowUp,
.e3-main-search-form .flatpickr-calendar .numInputWrapper .arrowDown,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .numInputWrapper
  .arrowUp,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .numInputWrapper
  .arrowDown {
  display: none;
}
.e4-modal-container .flatpickr-calendar .numInputWrapper:hover,
.e4-modal-container .flatpickr-calendar .numInputWrapper:focus,
.e3-search-bar .flatpickr-calendar .numInputWrapper:hover,
.e3-search-bar .flatpickr-calendar .numInputWrapper:focus,
.e3-mobile-search-bar .flatpickr-calendar .numInputWrapper:hover,
.e3-mobile-search-bar .flatpickr-calendar .numInputWrapper:focus,
.e3-main-search-form .flatpickr-calendar .numInputWrapper:hover,
.e3-main-search-form .flatpickr-calendar .numInputWrapper:focus,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .numInputWrapper:hover,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .numInputWrapper:focus {
  background: none;
}
.e4-modal-container .flatpickr-calendar select,
.e3-search-bar .flatpickr-calendar select,
.e3-mobile-search-bar .flatpickr-calendar select,
.e3-main-search-form .flatpickr-calendar select,
.e3-field-drop.e3-field-drop--datepicker .flatpickr-calendar select {
  -webkit-appearance: none;
}
.e4-modal-container .flatpickr-calendar .flatpickr-day,
.e3-search-bar .flatpickr-calendar .flatpickr-day,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day,
.e3-main-search-form .flatpickr-calendar .flatpickr-day,
.e3-field-drop.e3-field-drop--datepicker .flatpickr-calendar .flatpickr-day {
  border: 1px solid #eeeeee;
  border-radius: 0;
  margin-top: 2px;
  max-width: 42px;
  height: 42px;
  line-height: 42px;
}
.e4-modal-container .flatpickr-calendar .flatpickr-day.selected,
.e4-modal-container .flatpickr-calendar .flatpickr-day.startRange,
.e4-modal-container .flatpickr-calendar .flatpickr-day.endRange,
.e4-modal-container .flatpickr-calendar .flatpickr-day.selected.inRange,
.e4-modal-container .flatpickr-calendar .flatpickr-day.startRange.inRange,
.e4-modal-container .flatpickr-calendar .flatpickr-day.endRange.inRange,
.e4-modal-container .flatpickr-calendar .flatpickr-day.selected:focus,
.e4-modal-container .flatpickr-calendar .flatpickr-day.startRange:focus,
.e4-modal-container .flatpickr-calendar .flatpickr-day.endRange:focus,
.e4-modal-container .flatpickr-calendar .flatpickr-day.selected:hover,
.e4-modal-container .flatpickr-calendar .flatpickr-day.startRange:hover,
.e4-modal-container .flatpickr-calendar .flatpickr-day.endRange:hover,
.e4-modal-container .flatpickr-calendar .flatpickr-day.selected.prevMonthDay,
.e4-modal-container .flatpickr-calendar .flatpickr-day.startRange.prevMonthDay,
.e4-modal-container .flatpickr-calendar .flatpickr-day.endRange.prevMonthDay,
.e4-modal-container .flatpickr-calendar .flatpickr-day.selected.nextMonthDay,
.e4-modal-container .flatpickr-calendar .flatpickr-day.startRange.nextMonthDay,
.e4-modal-container .flatpickr-calendar .flatpickr-day.endRange.nextMonthDay,
.e3-search-bar .flatpickr-calendar .flatpickr-day.selected,
.e3-search-bar .flatpickr-calendar .flatpickr-day.startRange,
.e3-search-bar .flatpickr-calendar .flatpickr-day.endRange,
.e3-search-bar .flatpickr-calendar .flatpickr-day.selected.inRange,
.e3-search-bar .flatpickr-calendar .flatpickr-day.startRange.inRange,
.e3-search-bar .flatpickr-calendar .flatpickr-day.endRange.inRange,
.e3-search-bar .flatpickr-calendar .flatpickr-day.selected:focus,
.e3-search-bar .flatpickr-calendar .flatpickr-day.startRange:focus,
.e3-search-bar .flatpickr-calendar .flatpickr-day.endRange:focus,
.e3-search-bar .flatpickr-calendar .flatpickr-day.selected:hover,
.e3-search-bar .flatpickr-calendar .flatpickr-day.startRange:hover,
.e3-search-bar .flatpickr-calendar .flatpickr-day.endRange:hover,
.e3-search-bar .flatpickr-calendar .flatpickr-day.selected.prevMonthDay,
.e3-search-bar .flatpickr-calendar .flatpickr-day.startRange.prevMonthDay,
.e3-search-bar .flatpickr-calendar .flatpickr-day.endRange.prevMonthDay,
.e3-search-bar .flatpickr-calendar .flatpickr-day.selected.nextMonthDay,
.e3-search-bar .flatpickr-calendar .flatpickr-day.startRange.nextMonthDay,
.e3-search-bar .flatpickr-calendar .flatpickr-day.endRange.nextMonthDay,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.selected,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.startRange,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.endRange,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.selected.inRange,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.startRange.inRange,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.endRange.inRange,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.selected:focus,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.startRange:focus,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.endRange:focus,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.selected:hover,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.startRange:hover,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.endRange:hover,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.selected.prevMonthDay,
.e3-mobile-search-bar
  .flatpickr-calendar
  .flatpickr-day.startRange.prevMonthDay,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.endRange.prevMonthDay,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.selected.nextMonthDay,
.e3-mobile-search-bar
  .flatpickr-calendar
  .flatpickr-day.startRange.nextMonthDay,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.endRange.nextMonthDay,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.selected,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.startRange,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.endRange,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.selected.inRange,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.startRange.inRange,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.endRange.inRange,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.selected:focus,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.startRange:focus,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.endRange:focus,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.selected:hover,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.startRange:hover,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.endRange:hover,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.selected.prevMonthDay,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.startRange.prevMonthDay,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.endRange.prevMonthDay,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.selected.nextMonthDay,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.startRange.nextMonthDay,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.endRange.nextMonthDay,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.selected,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.startRange,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.endRange,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.selected.inRange,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.startRange.inRange,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.endRange.inRange,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.selected:focus,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.startRange:focus,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.endRange:focus,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.selected:hover,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.startRange:hover,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.endRange:hover,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.selected.prevMonthDay,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.startRange.prevMonthDay,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.endRange.prevMonthDay,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.selected.nextMonthDay,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.startRange.nextMonthDay,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.endRange.nextMonthDay {
  background: #00508a;
  border-color: #00508a;
}
.e4-modal-container .flatpickr-calendar .flatpickr-day.inRange,
.e3-search-bar .flatpickr-calendar .flatpickr-day.inRange,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.inRange,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.inRange,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -2px 0 0 #b1e6f7, 2px 0 0 #b1e6f7;
  box-shadow: -2px 0 0 #b1e6f7, 2px 0 0 #b1e6f7;
  border: 1px solid #b1e6f7;
}
.e4-modal-container .flatpickr-calendar .flatpickr-day.inRange,
.e4-modal-container .flatpickr-calendar .flatpickr-day.prevMonthDay.inRange,
.e4-modal-container .flatpickr-calendar .flatpickr-day.nextMonthDay.inRange,
.e4-modal-container .flatpickr-calendar .flatpickr-day.today.inRange,
.e4-modal-container
  .flatpickr-calendar
  .flatpickr-day.prevMonthDay.today.inRange,
.e4-modal-container
  .flatpickr-calendar
  .flatpickr-day.nextMonthDay.today.inRange,
.e4-modal-container .flatpickr-calendar .flatpickr-day:hover,
.e4-modal-container .flatpickr-calendar .flatpickr-day.prevMonthDay:hover,
.e4-modal-container .flatpickr-calendar .flatpickr-day.nextMonthDay:hover,
.e4-modal-container .flatpickr-calendar .flatpickr-day:focus,
.e4-modal-container .flatpickr-calendar .flatpickr-day.prevMonthDay:focus,
.e4-modal-container .flatpickr-calendar .flatpickr-day.nextMonthDay:focus,
.e3-search-bar .flatpickr-calendar .flatpickr-day.inRange,
.e3-search-bar .flatpickr-calendar .flatpickr-day.prevMonthDay.inRange,
.e3-search-bar .flatpickr-calendar .flatpickr-day.nextMonthDay.inRange,
.e3-search-bar .flatpickr-calendar .flatpickr-day.today.inRange,
.e3-search-bar .flatpickr-calendar .flatpickr-day.prevMonthDay.today.inRange,
.e3-search-bar .flatpickr-calendar .flatpickr-day.nextMonthDay.today.inRange,
.e3-search-bar .flatpickr-calendar .flatpickr-day:hover,
.e3-search-bar .flatpickr-calendar .flatpickr-day.prevMonthDay:hover,
.e3-search-bar .flatpickr-calendar .flatpickr-day.nextMonthDay:hover,
.e3-search-bar .flatpickr-calendar .flatpickr-day:focus,
.e3-search-bar .flatpickr-calendar .flatpickr-day.prevMonthDay:focus,
.e3-search-bar .flatpickr-calendar .flatpickr-day.nextMonthDay:focus,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.inRange,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.prevMonthDay.inRange,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.nextMonthDay.inRange,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.today.inRange,
.e3-mobile-search-bar
  .flatpickr-calendar
  .flatpickr-day.prevMonthDay.today.inRange,
.e3-mobile-search-bar
  .flatpickr-calendar
  .flatpickr-day.nextMonthDay.today.inRange,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day:hover,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.prevMonthDay:hover,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.nextMonthDay:hover,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day:focus,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.prevMonthDay:focus,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.nextMonthDay:focus,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.inRange,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.prevMonthDay.inRange,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.nextMonthDay.inRange,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.today.inRange,
.e3-main-search-form
  .flatpickr-calendar
  .flatpickr-day.prevMonthDay.today.inRange,
.e3-main-search-form
  .flatpickr-calendar
  .flatpickr-day.nextMonthDay.today.inRange,
.e3-main-search-form .flatpickr-calendar .flatpickr-day:hover,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.prevMonthDay:hover,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.nextMonthDay:hover,
.e3-main-search-form .flatpickr-calendar .flatpickr-day:focus,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.prevMonthDay:focus,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.nextMonthDay:focus,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.inRange,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.prevMonthDay.inRange,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.nextMonthDay.inRange,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.today.inRange,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.prevMonthDay.today.inRange,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.nextMonthDay.today.inRange,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day:hover,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.prevMonthDay:hover,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.nextMonthDay:hover,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day:focus,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.prevMonthDay:focus,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.nextMonthDay:focus {
  background: #b1e6f7;
}
.e4-modal-container .flatpickr-calendar .flatpickr-day.today,
.e3-search-bar .flatpickr-calendar .flatpickr-day.today,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.today,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.today,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.today {
  color: #ff7f00;
  font-weight: 500;
}
.e4-modal-container .flatpickr-calendar .flatpickr-day.today.disabled,
.e4-modal-container .flatpickr-calendar .flatpickr-day.today.flatpickr-disabled,
.e3-search-bar .flatpickr-calendar .flatpickr-day.today.disabled,
.e3-search-bar .flatpickr-calendar .flatpickr-day.today.flatpickr-disabled,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.today.disabled,
.e3-mobile-search-bar
  .flatpickr-calendar
  .flatpickr-day.today.flatpickr-disabled,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.today.disabled,
.e3-main-search-form
  .flatpickr-calendar
  .flatpickr-day.today.flatpickr-disabled,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.today.disabled,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.today.flatpickr-disabled {
  color: rgba(255, 127, 0, 0.6);
}
.e4-modal-container .flatpickr-calendar .startRange,
.e3-search-bar .flatpickr-calendar .startRange,
.e3-mobile-search-bar .flatpickr-calendar .startRange,
.e3-main-search-form .flatpickr-calendar .startRange,
.e3-field-drop.e3-field-drop--datepicker .flatpickr-calendar .startRange {
  position: relative;
  border-radius: 0 !important;
}
.e4-modal-container .flatpickr-calendar .startRange:before,
.e3-search-bar .flatpickr-calendar .startRange:before,
.e3-mobile-search-bar .flatpickr-calendar .startRange:before,
.e3-main-search-form .flatpickr-calendar .startRange:before,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .startRange:before {
  position: absolute;
  content: '';
  top: 50%;
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-left: 6px solid #00508a;
  border-bottom: 7px solid transparent;
  right: -5px;
  z-index: 1;
}
.e4-modal-container .flatpickr-calendar .endRange,
.e3-search-bar .flatpickr-calendar .endRange,
.e3-mobile-search-bar .flatpickr-calendar .endRange,
.e3-main-search-form .flatpickr-calendar .endRange,
.e3-field-drop.e3-field-drop--datepicker .flatpickr-calendar .endRange {
  position: relative;
  border-radius: 0 !important;
}
.e4-modal-container .flatpickr-calendar .endRange:before,
.e3-search-bar .flatpickr-calendar .endRange:before,
.e3-mobile-search-bar .flatpickr-calendar .endRange:before,
.e3-main-search-form .flatpickr-calendar .endRange:before,
.e3-field-drop.e3-field-drop--datepicker .flatpickr-calendar .endRange:before {
  position: absolute;
  content: '';
  top: 50%;
  -webkit-transform: translateY(-50%);
  -o-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-top: 7px solid transparent;
  border-right: 6px solid #00508a;
  border-bottom: 7px solid transparent;
  left: -7px;
  z-index: 1;
}
.e4-modal-container
  .flatpickr-calendar
  .flatpickr-day.selected.startRange
  + .endRange:not(:nth-child(7n + 1)),
.e4-modal-container
  .flatpickr-calendar
  .flatpickr-day.startRange.startRange
  + .endRange:not(:nth-child(7n + 1)),
.e4-modal-container
  .flatpickr-calendar
  .flatpickr-day.endRange.startRange
  + .endRange:not(:nth-child(7n + 1)),
.e3-search-bar
  .flatpickr-calendar
  .flatpickr-day.selected.startRange
  + .endRange:not(:nth-child(7n + 1)),
.e3-search-bar
  .flatpickr-calendar
  .flatpickr-day.startRange.startRange
  + .endRange:not(:nth-child(7n + 1)),
.e3-search-bar
  .flatpickr-calendar
  .flatpickr-day.endRange.startRange
  + .endRange:not(:nth-child(7n + 1)),
.e3-mobile-search-bar
  .flatpickr-calendar
  .flatpickr-day.selected.startRange
  + .endRange:not(:nth-child(7n + 1)),
.e3-mobile-search-bar
  .flatpickr-calendar
  .flatpickr-day.startRange.startRange
  + .endRange:not(:nth-child(7n + 1)),
.e3-mobile-search-bar
  .flatpickr-calendar
  .flatpickr-day.endRange.startRange
  + .endRange:not(:nth-child(7n + 1)),
.e3-main-search-form
  .flatpickr-calendar
  .flatpickr-day.selected.startRange
  + .endRange:not(:nth-child(7n + 1)),
.e3-main-search-form
  .flatpickr-calendar
  .flatpickr-day.startRange.startRange
  + .endRange:not(:nth-child(7n + 1)),
.e3-main-search-form
  .flatpickr-calendar
  .flatpickr-day.endRange.startRange
  + .endRange:not(:nth-child(7n + 1)),
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.selected.startRange
  + .endRange:not(:nth-child(7n + 1)),
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.startRange.startRange
  + .endRange:not(:nth-child(7n + 1)),
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.endRange.startRange
  + .endRange:not(:nth-child(7n + 1)) {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.e4-modal-container .flatpickr-calendar .flatpickr-current-month span.cur-month,
.e3-search-bar .flatpickr-calendar .flatpickr-current-month span.cur-month,
.e3-mobile-search-bar
  .flatpickr-calendar
  .flatpickr-current-month
  span.cur-month,
.e3-main-search-form
  .flatpickr-calendar
  .flatpickr-current-month
  span.cur-month,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-current-month
  span.cur-month {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.125rem;
}
.e4-modal-container .flatpickr-calendar .flatpickr-current-month input.cur-year,
.e3-search-bar .flatpickr-calendar .flatpickr-current-month input.cur-year,
.e3-mobile-search-bar
  .flatpickr-calendar
  .flatpickr-current-month
  input.cur-year,
.e3-main-search-form
  .flatpickr-calendar
  .flatpickr-current-month
  input.cur-year,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-current-month
  input.cur-year {
  font-weight: 500;
  font-size: 1.125rem;
  line-height: 1.125rem;
}
.e4-modal-container .flatpickr-calendar .flatpickr-day:hover,
.e3-search-bar .flatpickr-calendar .flatpickr-day:hover,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day:hover,
.e3-main-search-form .flatpickr-calendar .flatpickr-day:hover,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day:hover {
  background-color: #ff7f00;
  color: #fff;
}
.e4-modal-container .flatpickr-calendar .flatpickr-day.disabled,
.e4-modal-container .flatpickr-calendar .flatpickr-day.flatpickr-disabled,
.e3-search-bar .flatpickr-calendar .flatpickr-day.disabled,
.e3-search-bar .flatpickr-calendar .flatpickr-day.flatpickr-disabled,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.disabled,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.flatpickr-disabled,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.disabled,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.flatpickr-disabled,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.disabled,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.flatpickr-disabled {
  color: #d8d8d8;
  border: 1px solid #eeeeee;
  cursor: pointer;
}
.e4-modal-container
  .flatpickr-calendar
  .flatpickr-day.disabled
  .e3-calendar-price,
.e4-modal-container
  .flatpickr-calendar
  .flatpickr-day.flatpickr-disabled
  .e3-calendar-price,
.e3-search-bar .flatpickr-calendar .flatpickr-day.disabled .e3-calendar-price,
.e3-search-bar
  .flatpickr-calendar
  .flatpickr-day.flatpickr-disabled
  .e3-calendar-price,
.e3-mobile-search-bar
  .flatpickr-calendar
  .flatpickr-day.disabled
  .e3-calendar-price,
.e3-mobile-search-bar
  .flatpickr-calendar
  .flatpickr-day.flatpickr-disabled
  .e3-calendar-price,
.e3-main-search-form
  .flatpickr-calendar
  .flatpickr-day.disabled
  .e3-calendar-price,
.e3-main-search-form
  .flatpickr-calendar
  .flatpickr-day.flatpickr-disabled
  .e3-calendar-price,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.disabled
  .e3-calendar-price,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.flatpickr-disabled
  .e3-calendar-price {
  color: #d8d8d8;
}
.e4-modal-container .flatpickr-calendar .flatpickr-day.disabled,
.e4-modal-container .flatpickr-calendar .flatpickr-day.flatpickr-disabled:hover,
.e3-search-bar .flatpickr-calendar .flatpickr-day.disabled,
.e3-search-bar .flatpickr-calendar .flatpickr-day.flatpickr-disabled:hover,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.disabled,
.e3-mobile-search-bar
  .flatpickr-calendar
  .flatpickr-day.flatpickr-disabled:hover,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.disabled,
.e3-main-search-form
  .flatpickr-calendar
  .flatpickr-day.flatpickr-disabled:hover,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.disabled,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.flatpickr-disabled:hover {
  background-color: #fff;
  cursor: default;
  border-color: #eeeeee;
}
.e4-modal-container
  .flatpickr-calendar
  .flatpickr-day.disabled
  .e3-calendar-price,
.e4-modal-container
  .flatpickr-calendar
  .flatpickr-day.flatpickr-disabled:hover
  .e3-calendar-price,
.e3-search-bar .flatpickr-calendar .flatpickr-day.disabled .e3-calendar-price,
.e3-search-bar
  .flatpickr-calendar
  .flatpickr-day.flatpickr-disabled:hover
  .e3-calendar-price,
.e3-mobile-search-bar
  .flatpickr-calendar
  .flatpickr-day.disabled
  .e3-calendar-price,
.e3-mobile-search-bar
  .flatpickr-calendar
  .flatpickr-day.flatpickr-disabled:hover
  .e3-calendar-price,
.e3-main-search-form
  .flatpickr-calendar
  .flatpickr-day.disabled
  .e3-calendar-price,
.e3-main-search-form
  .flatpickr-calendar
  .flatpickr-day.flatpickr-disabled:hover
  .e3-calendar-price,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.disabled
  .e3-calendar-price,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.flatpickr-disabled:hover
  .e3-calendar-price {
  color: #d8d8d8 !important;
}
.e4-modal-container .flatpickr-calendar .flatpickr-day.today:hover,
.e3-search-bar .flatpickr-calendar .flatpickr-day.today:hover,
.e3-mobile-search-bar .flatpickr-calendar .flatpickr-day.today:hover,
.e3-main-search-form .flatpickr-calendar .flatpickr-day.today:hover,
.e3-field-drop.e3-field-drop--datepicker
  .flatpickr-calendar
  .flatpickr-day.today:hover {
  border-color: #fff;
}

.e4-modal-container .e4-modal .flatpickr-calendar:after,
.e4-modal-container .e4-modal .flatpickr-calendar:before {
  display: none;
}

.flatpickr-day:hover .e3-calendar-price {
  color: #fff !important;
}

.flatpickr-calendar .flatpickr-day.today.e3-day-green {
  color: #ffb56d !important;
}

.flatpickr-day.e3-day-green {
  background: #2eb200;
  color: #fff;
}
.flatpickr-day.e3-day-green .e3-calendar-price {
  color: #fff;
}

.flatpickr-day.e3-day-green.inRange {
  color: #32394c;
}
.flatpickr-day.e3-day-green.inRange .e3-calendar-price {
  color: #32394c;
}

.flatpickr-day.e3-date-red .e3-calendar-price {
  color: #d1031b;
}

.flatpickr-day.e3-date-red.inRange {
  color: #32394c;
}
.flatpickr-day.e3-date-red.inRange .e3-calendar-price {
  color: #32394c;
}

.flatpickr-day.e3-date-green .e3-calendar-price {
  color: #2eb200;
}

.flatpickr-day.e3-date-green.inRange {
  color: #32394c;
}
.flatpickr-day.e3-date-green.inRange .e3-calendar-price {
  color: #32394c;
}

.flatpickr-day.selected.startRange .e3-calendar-price,
.flatpickr-day.selected.endRange .e3-calendar-price {
  color: #fff;
}

.e3-calendar-price {
  font-size: 0.625rem;
  line-height: 0.625rem;
  position: absolute;
  white-space: nowrap;
  bottom: 1px;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  color: #aaa;
}
