.extra-main-nav {
  ul {
    @apply flex;
    width: calc(100% + 20px);
    margin-left: -10px;
    margin-right: -10px;

    > li {
      padding: 0 10px;
    }

    .extra-main-nav-item {
      @apply block text-sm font-medium font-body text-secondary hover:text-primary border-b-5 border-transparent transition-all duration-200 hover:border-b-primary py-2 px-5;

      &.active {
        @apply border-b-primary text-primary;
      }
    }
  }
}

.extra-main-nav-mobile {
  @apply pt-16 pb-16 mb-8;

  > li {
    @apply block;
  }
}

.extra-mobile-menu-window {
  .extra-main-nav-item {
    @apply block text-base font-medium font-body text-secondary hover:text-primary transition-all duration-200 py-8;

    &.active {
      @apply text-primary;
    }

    &-secondary {
      @apply block text-base font-normal font-body text-secondary hover:text-primary transition-all duration-200 py-8;

      &.active {
        @apply text-primary;
      }
    }
  }
}

.extra-menu-ghost {
  @apply md:hidden;
  height: 60px;
  z-index: -1;
}

.extra-menu-btn {
  .hamburger {
    @apply p-0 pt-20 pb-12;
  }

  .hamburger .hamburger-box {
    position: relative;
    display: inline-block;
    width: 28px;
    height: 20px;
  }

  .hamburger-inner,
  .hamburger-inner:before,
  .hamburger-inner:after {
    width: 28px;
    height: 3px;
  }

  .hamburger-inner:before {
    top: -6px;
  }

  .hamburger-inner:after {
    bottom: -6px;
  }
}

.extra-mobile-menu-window {
  @apply fixed w-full h-full overflow-auto bg-white md:hidden;
  z-index: 998;
  top: 0;
  left: 0;
  transition: all 0.3s;
  transform: translateY(-100%);

  &.is-open {
    transition: all 0.3s;
    transform: translateY(0%);
  }
}
