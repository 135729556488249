.extra-content {
  @apply max-w-[1360px];
  width: 100%;
}

.extra-content-top-bar {
  @apply max-w-[1426px];
  width: 100%;
}

.extra-content-sm {
  @apply max-w-[1100px];
  width: 100%;
}

.extra-gutter {
  @apply px-20 xl:px-56;
}

.extra-header-item {
  @apply h-full flex items-center;
  transform: skew(20deg);
  margin-left: -20px;
  border-bottom-left-radius: 8px;

  &-inner {
    @apply py-6 pl-40 pr-48;
    transform: skew(-20deg);
  }
}

.extra-lang-select {
  @apply flex;

  > li:not(:last-child) {
    @apply mr-12;
  }

  a {
    @apply pt-8 pb-10 block uppercase text-xs font-medium text-gray-500 h-full border-t-4 border-t-transparent transition-all duration-200;
  }

  a.active,
  a:hover {
    @apply border-t-primary text-secondary;
  }
}

.extra-logo {
  @apply block;
  width: 177px;
  height: 52px;
}

.extra-logo-mobile {
  @apply block;
  width: 115px;
  height: 32px;
}

.extra-footer-logo {
  @apply block;
  width: 113px;
  height: 106px;
}

.extra-footer-content {
  @apply text-white text-sm;

  p {
    @apply text-white text-sm;
  }

  p:not(:last-child) {
    /* @apply mb-6; */
  }

  a {
    @apply text-white text-sm inline-block border-b border-white transition-all duration-200 hover:border-primary hover:text-primary;
  }
}

.extra-report-intro {
  max-width: 600px;
}

.extra-logo-illustration {
  @apply absolute bottom-0 right-0;
  height: 500px;
  width: auto;
}
