.extra-table-tab-controls {
  @apply flex;
}

.extra-table-tab-control-item {
  @apply text-sm font-bold font-body rounded-b-base px-20 py-8 block text-secondary bg-gray-200 hover:bg-blue-100 transition-colors duration-200 mr-2;

  &[aria-selected='true'] {
    @apply bg-blue-100;
  }
}
