.extra-icon-button {
  @apply block py-8 px-15 text-sm transition-all duration-200 bg-transparent rounded-base hover:bg-gray-200 hover:text-secondary;

  &.active {
    @apply bg-gray-200 text-secondary;
  }

  &.disabled {
    @apply cursor-not-allowed;
  }
}

.extra-text-button {
  @apply font-bold font-heading transition-all duration-200 text-secondary hover:text-primary text-base leading-normal flex items-center;

  svg {
    @apply transition-all duration-200;
  }

  &[aria-expanded='true'] {
    svg {
      @apply transform -rotate-90;
    }
  }
}

.extra-button {
  @apply px-20 py-10 block transition-all duration-200;
  background: linear-gradient(190deg, #0fef01 0%, #00c500 100%);
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.16);
  border-radius: 5px;

  &:hover {
    @apply text-white;
    background: linear-gradient(190deg, #2eb200 0%, #2eb200 100%);
  }
}

.extra-button-plain {
  @apply px-20 py-10 block transition-all duration-200 bg-gray-100;
  box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.16);
  border-radius: 5px;

  &:hover {
    @apply bg-gray-200;
  }
}
