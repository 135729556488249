.e4-dropdown {
  @apply hidden flex-col absolute z-10 mt-4 top-[100%] left-0 bg-white border-5 rounded-lg border-blue-300 text-blue-300;
  --dropdown-move: 0px;
  --arrow-width: 12px;
  --border-width: 5px;
  width: 350px;
  max-width: calc(100vw - 20px);
  max-height: calc(100vh - 170px);
}

.e4-dropdown.e4-dropdown-open {
  @apply flex;
}

/* dropdown positions */
.e4-dropdown-bottom-right {
  @apply left-auto right-0;
}

.e4-dropdown-top-left {
  @apply top-auto bottom-[100%] mt-0 mb-4;
}

.e4-dropdown-top-right {
  @apply top-auto bottom-[100%] left-auto right-0 mt-0 mb-4;
}

/* dropdown arrow*/
.e4-dropdown-arrow-element.e4-dropdown-open:before {
  content: none !important; /* Internet explorer uses element */
}
.e4-dropdown-arrow {
  /* NB! margin-left is used by javascript */
  @apply pointer-events-none top-0 border-blue-300;
  transform: translateX(-15px);
  margin-top: -17px;
  left: 0;
  content: '';
  border-left: solid transparent;
  border-top: solid transparent;
  border-right: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  border-bottom-style: solid;
  position: absolute;
  pointer-events: none;
  border-width: 6px;
}

.e4-dropdown-arrow-left .e4-dropdown-arrow,
.e4-dropdown-arrow-left .e4-dropdown-arrow {
  transform: translateX(15px);
}

/* Dropdown top position */
.e4-dropdown-top-left .e4-dropdown-arrow,
.e4-dropdown-top-right .e4-dropdown-arrow {
  @apply top-auto bottom-0 mt-0;
  transform: rotate(180deg) translateX(15px);
  margin-bottom: -17px;
}

.e4-dropdown-top-left.e4-dropdown-arrow-left .e4-dropdown-arrow,
.e4-dropdown-top-right.e4-dropdown-arrow-left .e4-dropdown-arrow {
  transform: rotate(180deg) translateX(-15px);
}

/* Bordered dropdown style */
.e4-dropdown-bordered {
  @apply mt-8 mb-8 border-3;
}

.e4-dropdown-bordered .e4-dropdown-arrow {
  @apply absolute w-9 h-9 pointer-events-none border-t-3 border-r-3 border-l-0 border-b-0 bg-white border-blue-300 top-0;
  margin-top: -11px;
  content: '';
  transform: rotate(-45deg) translateX(-10px) translateY(-10px);
  z-index: 110;
}

.e4-dropdown-top-left.e4-dropdown-bordered .e4-dropdown-arrow,
.e4-dropdown-top-right.e4-dropdown-bordered .e4-dropdown-arrow {
  @apply mt-0 top-auto bottom-0;
  transform: rotate(135deg) translateX(10px) translateY(10px);
  margin-bottom: -11px;
}

.e4-dropdown-bordered.e4-dropdown-arrow-left .e4-dropdown-arrow,
.e4-dropdown-bordered.e4-dropdown-arrow-left .e4-dropdown-arrow {
  transform: rotate(-45deg) translateX(10px) translateY(10px);
}

.e4-dropdown-top-left.e4-dropdown-arrow-left.e4-dropdown-bordered
  .e4-dropdown-arrow,
.e4-dropdown-top-right.e4-dropdown-arrow-left.e4-dropdown-bordered
  .e4-dropdown-arrow {
  transform: rotate(135deg) translateX(-10px) translateY(-10px);
}

/* Dropdown colors */
.e4-dropdown-danger {
  @apply border-alert;
}
.e4-dropdown-danger:before,
.e4-dropdown-danger .e4-dropdown-arrow {
  @apply border-alert;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

/* .e4-dropdown-warning {
  @apply border-yellow-600;
}
.e4-dropdown-warning:before,
.e4-dropdown-warning .e4-dropdown-arrow {
  @apply border-yellow-600;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
}

.e4-dropdown-success-alert {
  @apply border-green-500;
}
.e4-dropdown-success-alert:before,
.e4-dropdown-success-alert .e4-dropdown-arrow {
  @apply border-green-500;
  border-top-color: transparent;
  border-left-color: transparent;
  border-right-color: transparent;
} */

/* Dropdown bordered arrow colors */
.e4-dropdown-bordered.e4-dropdown-danger:before,
.e4-dropdown-bordered.e4-dropdown-danger .e4-dropdown-arrow {
  @apply border-alert;
}

/* .e4-dropdown-bordered.e4-dropdown-warning:before,
.e4-dropdown-bordered.e4-dropdown-warning .e4-dropdown-arrow {
  @apply border-yellow-600;
}

.e4-dropdown-bordered.e4-dropdown-success-alert:before,
.e4-dropdown-bordered.e4-dropdown-success-alert .e4-dropdown-arrow {
  @apply border-green-500;
} */

@screen xs {
  .e4-dropdown {
    max-width: calc(100vw - 32px);
    max-height: 600px;
    min-width: 350px; /* added for wider inputs */
    width: 100%; /* added for wider inputs */
  }
}

.e4-dropdown-lg {
  width: 350px;
}

@screen xs {
  .e4-dropdown-lg {
    max-width: calc(100vw - 32px);
    max-height: 600px;
    min-width: 400px; /* added for wider inputs */
    width: 100%; /* added for wider inputs */
  }
}

.e4-dropdown.e4-dropdown-sm {
  width: 200px;
}

@screen xs {
  .e4-dropdown-sm {
    max-width: calc(100vw - 32px);
    max-height: 600px;
    min-width: 200px; /* added for wider inputs */
    width: 100%; /* added for wider inputs */
  }
}

.e4-dropdown.e4-dropdown-xs {
  width: 150px;
  min-width: 0;
}

.e4-dropdown.e4-dropdown-xxs {
  width: 64px;
  min-width: 0;
}

@screen xs {
  .e4-dropdown-xs {
    max-width: calc(100vw - 32px);
    max-height: 600px;
    min-width: 150px; /* added for wider inputs */
    width: 100%; /* added for wider inputs */
  }
}

.e4-dropdown-close:hover {
  @apply opacity-80;
}

.e4-dropdown.e4-dropdown-calendar {
  width: 318px;
  min-width: 318px;
  max-width: calc(100vw - 32px);
  max-height: calc(100vh - 170px);
}

.e4-dropdown-calendar .flatpickr-day.hidden {
  display: unset;
}

.e4-dropdown-calendar .flatpickr-calendar .flatpickr-day.offer {
  border-bottom-color: #2eb200;
  border-bottom-width: 3px;
}

.e4-dropdown-calendar .dayContainer {
  padding-left: 6px;
  padding-right: 6px;
}

.e4-dropdown-calendar .dayContainer + .dayContainer {
  box-shadow: none;
}

.e4-dropdown-calendar .flatpickr-weekdays .flatpickr-weekdaycontainer {
  padding-left: 6px;
  padding-right: 6px;
}

@screen md {
  .e4-dropdown.e4-dropdown-calendar {
    width: 625px;
    max-height: calc(100vh - 100px);
  }
}

.e4-dropdown-header {
  @apply h-15 flex-none;
}

.e4-dropdown-footer {
  @apply flex-none pl-10 pr-16 border-t border-blue-300;
}

.calendar-range-start,
.calendar-range-end {
  @apply relative;
}

.calendar-range-start:before {
  @apply pointer-events-none border-blue-300;
  content: '';
  border-right: solid transparent;
  border-top: solid transparent;
  border-bottom: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  border-left-style: solid;
  position: absolute;
  right: -12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  border-width: 6px;
}

.calendar-range-end:before {
  @apply pointer-events-none border-blue-300;
  content: '';
  border-left: solid transparent;
  border-top: solid transparent;
  border-bottom: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  border-right-style: solid;
  position: absolute;
  left: -12px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  border-width: 6px;
}

/* .e4-dropdown-yellow {
  @apply bg-yellow-100 border-5 rounded-lg border-yellow-600;
}

.e4-dropdown-yellow-open:before {
  @apply pointer-events-none bottom-0 -mb-4 ml-5 border-yellow-600;

  content: '';
  border-left: solid transparent;
  border-top: solid transparent;
  border-right: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  right: 15px;
  border-bottom-style: solid;
  position: absolute;
  pointer-events: none;
  border-width: 6px;
} */

/* .e4-dropdown-filter .e4-dropdown-arrow {
  transform: translateX(-5px);
}

.e4-dropdown-open .e4-input-icon-triangle-left {
  @apply transform -rotate-90 -mt-1 mr-3;
}

.e4-dropdown-open + .e4-input-icon-triangle-left {
  @apply transform -rotate-90 -mt-1 mr-3;
}

.e4-dropdown-infotext {
  @apply absolute top-0 left-0 pt-2 pl-3 z-10 text-sm leading-snug;
  width: calc(100% - 40px);

  @screen md-down {
    @apply hidden;
  }
} */
