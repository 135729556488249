div.dataTables_wrapper {
  width: 100%;

  table {
    width: 100% !important;
  }
}

.dataTables_info {
  @apply pl-40 font-heading text-base font-bold sm-down:text-right;
}

.dataTables_length {
  @apply pr-40 font-heading;

  label {
    @apply flex items-center;
  }

  select {
    @apply text-base font-bold leading-normal rounded-base outline-none shadow-input focus:shadow-inner-focus px-10 pb-2 pt-4 w-full mx-5 cursor-pointer;
  }
}

.extra-table {
  th {
    @apply text-left text-sm font-bold font-heading text-gray-500 bg-gray-200 px-10 py-6;
  }

  td {
    @apply font-body;
  }

  th,
  td {
    @apply border border-gray-200 text-sm px-12 py-8 text-gray-500 whitespace-nowrap;
  }

  tbody tr:hover {
    @apply bg-gray-200;
  }

  a {
    @apply text-secondary;
  }

  &-price,
  th.extra-table-price {
    @apply text-right;
  }
}

.extra-table--compact {
  th,
  td {
    @apply px-6 py-8;
  }
}

.dataTables_paginate {
  @apply flex justify-center mt-40 text-sm;

  > span {
    @apply flex;
  }

  .paginate_button {
    @apply px-8 py-4 block rounded-base mx-2 bg-transparent transition-all duration-200 font-bold font-heading cursor-pointer;

    &.previous,
    &.next,
    &.current {
      @apply bg-gray-300 text-secondary;
    }

    &:hover {
      @apply bg-gray-200 shadow-input;
    }

    &.disabled {
      @apply bg-gray-200 shadow-input pointer-events-none opacity-50;
    }

    &.previous {
      @apply relative pl-20;

      &:before {
        @apply absolute;
        top: 10px;
        left: 8px;
        content: '';
        width: 7px;
        height: 9px;
        background: url('./images/icon-back.svg') no-repeat center center;
        background-size: 7px 9px;
      }
    }

    &.next {
      @apply relative pr-20;

      &:before {
        @apply absolute;
        top: 10px;
        right: 8px;
        content: '';
        width: 7px;
        height: 9px;
        background: url('./images/icon-back.svg') no-repeat center center;
        background-size: 7px 9px;
        transform: rotate(180deg);
      }
    }
  }
}

.extra-table-info {
  @apply flex justify-between items-center pb-16 sm-down:flex-wrap;
}

thead tr th.sorting {
  @apply cursor-pointer;
}

thead tr th.sorting span {
  @apply relative pr-16;

  &:before {
    @apply absolute opacity-50;
    top: 2px;
    right: 0px;
    content: '';
    width: 7px;
    height: 9px;
    background: url('./images/sort-arrow-up.svg') no-repeat center center;
    background-size: 7px 9px;
  }

  &:after {
    @apply absolute opacity-50;
    top: 8px;
    right: 0px;
    content: '';
    width: 7px;
    height: 9px;
    background: url('./images/sort-arrow-down.svg') no-repeat center center;
    background-size: 7px 9px;
  }
}

thead tr th.sorting_asc span:before {
  @apply opacity-100;
}

thead tr th.sorting_desc span:after {
  @apply opacity-100;
}
