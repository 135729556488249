input,
textarea,
select {
  @apply appearance-none;
}

textarea:placeholder {
  color: red;
}

/* All inputs must have font-normal and py-4 in order to work correctly in iOS devices */

/* Radio button */
.e4-radio-button,
.e4-checkbox-button {
  @apply block border border-gray-500 cursor-pointer text-sm leading-snug rounded-md my-2 px-3 py-3 whitespace-nowrap flex-grow text-center;
}

@screen xs {
  .e4-radio-button,
  .e4-checkbox-button {
    @apply flex-grow-0;
  }
}

input:checked + .e4-radio-button,
input:checked + .e4-checkbox-button {
  @apply bg-blue-300 border-blue-300;
}

input:checked + .e4-radio-button-dark-bg,
input:checked + .e4-checkbox-button-dark-bg {
  @apply bg-blue-600 border-blue-600 text-white;
}

/* Radio button with white text and dark bg */
.e4-radio-button.e4-radio-button-white,
.e4-checkbox-button.e4-checkbox-button-white {
  @apply border-white text-white;
}

@screen md {
  .e4-radio-button.e4-radio-button-white,
  .e4-checkbox-button.e4-checkbox-button-white {
    @apply px-8;
  }
}

input:checked + .e4-radio-button.e4-radio-button-white,
input:checked + .e4-checkbox-button.e4-checkbox-button-white {
  @apply bg-blue-600 border-white;
}

/* Radio button with white text and white bg */
.e4-radio-button.e4-radio-button-white-bg,
.e4-checkbox-button.e4-checkbox-button-white-bg {
  @apply border-white text-white;
}

@screen md {
  .e4-radio-button.e4-radio-button-white-bg,
  .e4-checkbox-button.e4-checkbox-button-white-bg {
    @apply px-8;
  }
}

input:checked + .e4-radio-button.e4-radio-button-white-bg,
input:checked + .e4-checkbox-button.e4-checkbox-button-white-bg {
  @apply bg-white border-white text-blue-500;
}

/* Input component */
input:focus + .e4-input-icon svg,
button:focus + .e4-input-icon svg {
  @apply text-primary;
}

/* Input focus trigger */
.e4-focus,
input.e4-focus,
textarea.e4-focus {
  @apply shadow-inner-focus;
}

input.e4-focus + .e4-input-icon svg,
button.e4-focus + .e4-input-icon svg {
  @apply text-primary;
}

/* Input error trigger */
.e4-error,
input.e4-error,
textarea.e4-error,
button.e4-error {
  @apply shadow-inner-focus-error bg-red-100;
}

.e4-checkbox.e4-error + label:before,
.e4-checkbox-alt.e4-error + label,
.e4-radio.e4-error + label:before {
  @apply border-alert  bg-red-100;
}

input.e4-error + .e4-input-icon svg,
button.e4-error + .e4-input-icon svg {
  @apply text-red-100;
}

/* Custom radio */
.e4-radio {
  @apply absolute;
  left: -9999px;
}

.e4-radio + label {
  @apply flex items-center leading-snug relative pl-12 py-3 cursor-pointer;
}

.e4-radio + label:before {
  @apply absolute left-0 w-8 h-8 rounded-full border border-gray-500;
  content: '';
  top: 50%;
  transform: translateY(-50%);
}

.e4-radio:focus + label:before {
  @apply border-primary;
}

.e4-radio + label:after {
  @apply absolute rounded-full bg-blue-600 opacity-0;
  content: '';
  left: 4px;
  top: calc(50% - 4px);
  width: 8px;
  height: 8px;
}

.e4-radio:checked + label::after {
  @apply opacity-100;
}

/* Custom checkbox */
.e4-checkbox {
  @apply absolute;
  left: -9999px;
}

.e4-checkbox + label {
  @apply flex items-center text-sm leading-snug relative pl-24 py-10 cursor-pointer text-blue-300;
}

.e4-checkbox + label:before {
  @apply absolute left-0 w-16 h-16 rounded-md border border-gray-400;
  content: '';
  top: 50%;
  transform: translateY(-50%);
  margin-top: -1px;
}

.e4-checkbox:focus + label:before {
  @apply border-primary;
}

.e4-checkbox + label:after {
  @apply absolute rounded-full border border-blue-600 opacity-0;
  content: '';
  left: 5px;
  top: calc(50% - 6px);
  width: 6px;
  height: 9px;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.e4-checkbox:checked + label::after {
  @apply opacity-100;
}

/* Checkbox hover action */
.e4-checkbox + label.js-hover-action:hover span {
  @apply hidden;
}

.e4-checkbox + label.js-hover-action:hover button {
  @apply block;
}

/* List checkbox */
.e4-checkbox-button-alt {
  @apply relative whitespace-nowrap text-sm leading-snug  my-3 -mx-5;
}

.e4-checkbox-button-alt label {
  @apply py-5 rounded-md cursor-pointer;
  padding-left: 35px;
  padding-right: 10px;
}

.e4-checkbox-button-alt label:before {
  @apply ml-5;
}

.e4-checkbox-button-alt label:after {
  left: 15px;
}

.e4-checkbox-button-alt input:checked + label {
  @apply bg-blue-300;
}

.e4-checkbox-button-alt:hover label {
  @apply bg-gray-400;
}

.e4-checkbox-alt-wrap {
  @applu leading-snug;
  position: relative;
  padding-left: 24px;
  padding-bottom: 2px;
}

.e4-checkbox-alt-wrap input.e4-checkbox-alt {
  @apply absolute;
  left: -9999px;
}

.e4-checkbox-alt + label {
  @apply absolute left-0 w-8 h-8 rounded-md border border-gray-500;
  content: '';
  top: 50%;
  transform: translateY(-50%);
}

.e4-checkbox-alt:focus + label {
  @apply border-primary;
}

.e4-checkbox-alt + label:after {
  @apply absolute rounded-full border border-blue-600 opacity-0;
  content: '';
  left: 4px;
  top: calc(50% - 5px);
  width: 6px;
  height: 9px;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.e4-checkbox-alt:checked + label::after {
  @apply opacity-100;
}

.e4-checkbox-alt-wrap a {
  @apply text-blue-500;
  text-decoration: underline;
}

.e4-checkbox-alt-wrap a:hover {
  @apply text-primary;
  text-decoration: underline;
}

.e4-bottom-drawer-content .e4-checkbox-alt-wrap a {
  @apply text-primary;
  text-decoration: underline;
}

.e4-bottom-drawer-content .e4-checkbox-alt-wrap a:hover {
  @apply text-white;
  text-decoration: underline;
}

/* New form styles */
.extra-form-wrap {
  @apply mb-24 flex flex-wrap;
  max-width: 870px;
  margin-left: -10px;
  margin-right: -10px;

  &-item {
    @apply px-10 pt-12 xs:pt-24;
  }

  &-actions {
    @apply flex justify-between w-full pt-24 xs-down:flex-col;
    margin-left: 10px;
    margin-right: 10px;
  }
}

.extra-form-item-label {
  @apply font-body text-sm text-blue-300 font-heading;
}

.extra-form-input {
  @apply text-base font-normal leading-normal rounded-base outline-none shadow-input focus:shadow-inner-focus px-10 pb-6 pt-8 w-full;

  &::placeholder {
    @apply text-base;
  }
}

.extra-form-input-calendar {
  @apply text-base font-medium leading-normal rounded-base outline-none shadow-input focus:shadow-inner-focus px-10 pt-8 pb-6 pr-40 truncate relative w-full text-left;

  &::placeholder {
    @apply text-base;
  }
}

.e4-input-icon {
  @apply absolute top-0 right-0 bottom-0 flex items-center justify-center pointer-events-none;

  svg {
    @apply flex-shrink-0;
    margin-right: 10px;
  }
}

.extra-form-select-wrap {
  @apply relative;

  select {
    @apply text-base font-normal leading-normal rounded-base outline-none shadow-input focus:shadow-inner-focus px-10 pb-6 pt-8 w-full cursor-pointer;
  }

  &:after {
    @apply absolute pointer-events-none;
    content: '';
    width: 0;
    height: 0;
    border-top: 6px solid transparent;
    border-right: 8px solid #32394c;
    border-bottom: 6px solid transparent;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.extra-checkboxes-list {
  @apply flex flex-wrap w-full;

  > li {
    /* flex: 1; */
    @apply pr-20;
  }

  label {
    @apply whitespace-nowrap;
  }
}

.extra-form-wrap-item {
  .select2-selection.select2-selection--multiple {
    @apply text-base font-normal leading-normal rounded-base outline-none shadow-input focus:shadow-inner-focus pl-6 pb-6 pt-2 pr-20 w-full border-none;
    min-height: 38px;

    .select2-selection__choice {
      @apply bg-blue-100 border-none pl-5 pr-20;

      button.select2-selection__choice__remove {
        @apply border-none left-auto right-0;
        padding: 1px 6px 0 6px;
      }

      span.select2-selection__choice__display {
        @apply text-sm;
      }
    }

    .select2-search.select2-search--inline textarea {
      @apply text-base text-blue-300;
      height: 24px;

      &::placeholder {
        @apply text-base text-blue-300;
      }
    }
  }
}
