body {
  @apply text-base font-body leading-normal text-blue-300;
}

h1,
h2,
h3,
h4,
h5 {
  @apply font-heading font-medium;
}

.extra-text-link {
  @apply font-body inline-block text-secondary transition-all duration-200 hover:text-primary;
}

.extra-report-title,
.extra-content-title {
  @apply text-lg font-medium font-heading text-primary mb-6;
}
