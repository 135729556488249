@import 'tailwindcss/base';
@import 'styles/base';
@import 'tailwindcss/components';

@import '../node_modules/flatpickr/dist/flatpickr.min';
@import '../node_modules/hamburgers/dist/hamburgers.min';
@import '../node_modules/select2/dist/css/select2.min';

/* Custom components start */
@import 'styles/components';
@import 'styles/dropdown';
@import 'styles/buttons';
@import 'styles/inputs';
@import 'styles/flatpickr';
@import 'styles/tables';
@import 'styles/nav';
@import 'styles/tabs';
@import 'styles/text-content';

@import 'tailwindcss/utilities';
